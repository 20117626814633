

export default function Footer(){


    return (
        <div className="footer">

        <h4 style={{fontFamily:'Montserrat'}}>Made with ❤️ by Fardin.</h4>

        </div>
    );

}