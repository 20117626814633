import MediaCard from "./MediaCard";

import {Grid} from "@mui/material";
import React from "react";

import ExperienceCard from "./ExperienceCard";
import corona from "./coronanet.png";
import portfolio from "./portfolio.jpeg";
import linalg from "./linalg.png";
import sort from "./sort.gif";
import leetdoc from "./leetdoc.gif"
import bbrands from "./bbrands.jpeg";
import stitch from "./stitch.gif";
import savorite from "./savorite.gif";
import projectX from "./projectX.png";
import momentum from "./momentum.png";
import pg from "./pg.png";
import fsb from "./fsb.jpeg";
import ms from "./ms.jpeg";
import mais from "./mais.jpeg";
import myms from "./myms.jpeg";
import icpc from "./icpc.png";

export default function Projects(){

    return (
        <div id="projects" className="project-container">
        <center><i class="fa-solid fa-briefcase code-icon"></i></center>
        <h1 style={{textAlign:"center"}} className="section-title">Experience</h1> 

        <h3 style={{fontFamily:"Montserrat",textAlign:"center"}}> My work experiences range from being a TA for my favorite
        Linear Algebra course, to developing software at a big investment bank, to tutoring students about computer science, competitve programming, and more...</h3>

        <Grid container align="center" >

        <Grid item xs={12} md={6} lg={4}>
            <ExperienceCard title="Full Stack Developer Intern"
            company="Morgan Stanley - Montreal, Canada"
             image={myms}
             date = "Aug 2023 - Present"
             description = {["Leading a team of 2 interns developing the UI of a security dashboard, modernizing existing backend services, and setting up CI/CD pipelines."]}
             stack = {["Angular", "TypeScript", "Java", "JUnit5", "Spring Boot", "SQL", "IBM Db2", "Jenkins", "CI/CD" ]}
            />
            </Grid>

        <Grid item xs={12} md={6} lg={4}>
            <ExperienceCard title="Software Engineer Intern"
            company="Morgan Stanley - Montreal, Canada"
             image={ms}
             date = "May 2023 - Aug 2023"
             description = {["Member of an agile team building a dashboard and associated services for managing security data and entitlements across the entire firm."]}
             stack = {["Angular", "AG Grid", "TypeScript", "Spring Framework", "SQL", "Gradle", "Linux", "Sphinx" ]}
            />
            </Grid>


        <Grid item xs={12} md={6} lg={4}>
            <ExperienceCard title="Computer Science Helpdesk Tutor"
            company="McGill Computer Science Undergraduate Society"
             image={corona}
             date = "Sep 2022 - Jan 2023"
             description={["Tutoring Computer Science students for 5+ CS courses, including Algorithms and Data Structures and OOP (Java, Python)."]}
             stack = {["Python","Java","OOP","Data Structures","Algorithms"]}
            />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
            <ExperienceCard title="Honorable Mention, ICPC Northeast North American Regional Contest"
            company="International Collegiate Programming Contest"
             image={icpc}
             date = "Feb 2023"
             description={[""]}
             stack = {["Competitive Programming","Data Structures","Algorithms", "Problem Solving"]}
            />
            </Grid>


            <Grid item xs={12} md={6} lg={4}>
            <ExperienceCard title="Undergraduate Teaching Assistant, Linear Algebra"
            company="McGill University"
             image="https://miro.medium.com/max/3080/0*BR949eNWL2i-Sj4R"
             date = "Sep 2021 - Jan 2022"
             description = {["Awarded the Tomlinson Engagement Award for Mentorship for MATH 133 - Linear Algebra & Geometry."]}
             stack={["Teaching","Maths","Linear Algebra"]}
            />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
            <ExperienceCard title="MAIS 202: Accelerated Introduction to Machine Learning Bootcamp"
            company="McGill University"
             image={mais}
             date = "Sep 2021 - Jan 2022"
             description = {["Selected as one of the 25 students for an accelerated intro to Machine Learning Bootcamp where I led a team of 3 to develop an interactive Deep Learning Constellation Classifier project."]}
             stack={["Convolutional Neural Networks","Flask","Python", "Kaggle", "Tensorflow", "Keras", "HTML/CSS"]}
            />
            </Grid>

        </Grid>

        <center><i class="fa-solid fa-laptop-code code-icon"/></center>
        <h1 style={{textAlign:"center"}} className="section-title">Projects</h1>  

        <h2 style={{fontFamily:"Montserrat",color:"#3a42a3",textAlign:"center"}}>Personal Projects</h2> 

        <h3 style={{fontFamily:"Montserrat",textAlign:"center"}}>I love creating fun web applications. I enjoy building and hosting ML models, algorithm visualizers and other Full-Stack applications. Here are some of the
        projects I've made:</h3>


        <Grid container align="center">

        <Grid item xs={12} md={6} lg={4}>
            <MediaCard title="Savorite - Social Media Food Review App"
             image={savorite} 
             description="
             Savorite is a social media application to share your favorite meals!
             Find out the most popular food suggestions, recommend your favorite dishes and explore cuisine from all across the world. 🌎"
             gitLink="https://github.com/nilu-24/savorite"
             webLink="https://savorite.netlify.app/"
             stack = {["React.js","Node.js","Express.js","MongoDB","GraphQL"]}
            />
            </Grid>

        <Grid item xs={12} md={6} lg={4}>
            <MediaCard title="Momentum.io - 2nd Place, Morgan Stanley Hackathon"
             image={momentum} 
             description="Momentum.io is a social platform where the youth can collaborate and share their ideas and challenges, based on the UN Sustainability Goals."
             gitLink="https://github.com/nilu-24/Momentum.io"
             webLink="https://github.com/nilu-24/Momentum.io"
             stack = {["Angular","Django","SQLite","BERT","Angular Material"]}
            />
            </Grid>


        <Grid item xs={12} md={6} lg={4}>
            <MediaCard title="LeetDoc - Coding Interview Preparation Platform"
             image={leetdoc} 
             description="LeetDoc is an online IDE platform to help you practice technical coding interview questions in a better way. Features include a Compiler, Timer, Drawing Pad, Notepad and much more."
             gitLink="https://github.com/nilu-24/LeetDoc"
             webLink="https://leetdoc.netlify.app/"
             stack = {["React.js","Node.js","Flask","bs4","REST APIs"]}
            />
            </Grid>


        <Grid item xs={12} md={6} lg={4}>
            <MediaCard title="AlgoRhythms - Colorful Algorithm Visualizer"
             image={sort} 
             description="AlgoRhythms is a fun sorting algorithm visualizer which sorts colorful arrays based on hue values. I built this web application to visualize the satisfying patterns and rhythms of these sorting algorithms on colorful arrays."
             gitLink="https://github.com/nilu-24/algorhythms"
             webLink="https://algorhythms.netlify.app/"
             stack = {["React.js","Async-Await","Sorting"]}
            />
            </Grid>


            <Grid item xs={12} md={6} lg={4}>
            <MediaCard title="AI Healthcare App - Winner of CodeJam(XI)"
             image={stitch} 
             description="Stitch.ai is a story-based social community which helps connect people suffering from similar health conditions using the power of Natural Language Processing."
             gitLink="https://github.com/nilu-24/Stitch.ai"
             webLink="https://devpost.com/software/stitch-ai"
             stack = {["Streamlit","Firebase","Docker","GCP","NLP","AWS Medical"]}
            />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <MediaCard title="Deep Learning Constellation Classifier"
             image="https://d112y698adiu2z.cloudfront.net/photos/production/software_photos/001/760/442/datas/original.gif"
             description="CanvaStar is a Full Stack Deep Learning Flask application which can classify constellations in the sky using Convolutional Neural Networks."
             gitLink="https://github.com/nilu-24/canvastar"
             webLink="https://devpost.com/software/canvastar"
             stack = {["Python","Keras","Flask","Deep Learning","CNN"]}
            />
            </Grid>


            <Grid item xs={12} md={6} lg={4}>
            <MediaCard title="Linear Algebra Concept Blog"
             image={linalg} 
             description="I build this blog while working as a TA for MATH 133 - Linear Algebra & Geometry for students to revise crucial concepts and topics in linear algebra."
             gitLink="https://github.com/nilu-24/nilu-24.github.io"
             webLink="https://nilu-24.github.io/"
             stack = {["GitHub Pages","Jekyll","Markdown","Linear Algebra"]}
            />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
            <MediaCard title="What2Wear? - Clothing Recommender"
             image="https://d112y698adiu2z.cloudfront.net/photos/production/software_photos/001/803/168/datas/original.gif" 
             description="What2Wear? is a simple fully responsive website which recommends clothing using a heuristic algorithm based on
             the weather conditions in any location.  "
             gitLink="https://github.com/nilu-24/What2Wear"
             webLink="https://devpost.com/software/mchacks9-proj"
             stack = {["HTML","CSS","Bootstrap","JavaScript","OpenWeather API"]}
            />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
            <MediaCard title="Portfolio Website"
             image={portfolio} 
             description="A fully responsive Personal Portfolio Website created using React.js and Material UI."
             gitLink="https://github.com/nilu-24/my-portfolio"
             webLink="https://fardin-abdullah.com/"
             stack = {["React.js","Material UI","AWS S3"]}
            />
            </Grid>

        </Grid>
        

        

        </div>
    );


}